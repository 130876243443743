<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title> Añadir dispositivo </v-card-title>

        <v-card-text class="mt-8">
          <v-form @submit.prevent="save()" ref="formNewDevice">
            <v-row>
              <v-col>
                <v-select
                  v-model="device.deviceProductId"
                  label="Seleccione uno de nuestros productos"
                  :items="devices"
                  :rules="[(v) => !!v || 'Producto es requerido']"
                  outlined
                  item-text="name"
                  item-value="id"
                  @change="changeMeasurementUnit"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="device.device_external_id"
                  outlined
                  label="Id"
                  :rules="[(v) => !!v || 'Id es requerido']"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="device.alias"
                  outlined
                  label="Alias"
                  :rules="[(v) => !!v || 'Alias es requerido']"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  outlined
                  v-model="device.gps"
                  label="GPS (lat, long)"
                  :rules="[(v) => !!v || 'GPS es requerido']"
                  @click="openMapGps"
                ></v-text-field>
              </v-col>
              <v-col v-if="isTelemetry" lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="device.measurementUnit"
                  outlined
                  :label="measurementUnit"
                  :rules="[
                    (v) => !!v || 'Unidad es requerido',
                    (v) => v > 0 || 'Unidad debe ser un numero',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="device.suministro"
                  outlined
                  label="Suministro"
                ></v-text-field>
              </v-col>
              <v-col
                v-for="select in device.selects"
                v-if="select.state"
                :key="select.name"
                lg="6"
                class="col-12 my-0 py-0"
              >
                <v-select
                  v-model="select.valueSelected"
                  :items="select.values"
                  item-value="key"
                  item-text="value"
                  outlined
                  :rules="[(v) => !!v || `${select.name} es requerido`]"
                  :label="select.name"
                ></v-select>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-select
                  v-model="device.userIdN1"
                  :items="usersN1"
                  item-text="email"
                  item-value="id"
                  outlined
                  label="Usuario N1"
                  :rules="[(v) => !!v || 'Usuario N1 es requerido']"
                  @change="getUserN2()"
                ></v-select>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-select
                  v-model="device.userId"
                  :items="usersN2"
                  item-text="email"
                  item-value="id"
                  outlined
                  label="Usuario N2"
                  :rules="[(v) => !!v || 'Usuario N2 es requerido']"
                ></v-select>
              </v-col>
              <v-col lg="12" class="col-12 my-0 py-0">
                <v-textarea
                  v-model="device.commit"
                  outlined
                  label="Comentario"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-col> </v-col>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" @click="close()" dark> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save()" dark> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <view-map-component ref="viewMap" @changeCoordinates="changeCoordinates" />

    <wait-gps-component
      ref="waitGps"
      :dialog-wait-gps="dialogWaitGps"
    ></wait-gps-component>
  </div>
</template>
<script>
import BackendApi from "@/services/backend.service";

import ViewMapComponent from "./ViewMapComponent.vue";
import WaitGpsComponent from "./WaitGpsComponent.vue";
export default {
  components: {
    ViewMapComponent,
    WaitGpsComponent,
  },
  data() {
    return {
      usersN1: null,
      selects: [],
      dialogWaitGps: false,
      devices: [],
      device: {
        userIdN1: null,
        deviceProductId: null,
        device_external_id: null,
        alias: null,
        gps: null,
        measurementUnit: null,
        imp: null,
        transmissionPeriod: null,
        reconfigurationPeriod: null,
        localTransmission: null,
        localUplink: null,
        suministro: null,
        userId: null,
        selects: [],
      },
      auxSelects: [],
      measurementUnit: "IMP/M3",
      usersN2: [],
      dialog: false,
      coordinates: {
        Latitude: 0,
        Longitude: 0,
      },
      isUpdate: false,
      deviceClass: null,
    };
  },
  created() {
    this.getProductos();
    this.getUserN1();
  },
  computed: {
    isTelemetry() {
      if (!this.deviceClass) return false;

      return this.deviceClass.telemetry;
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.isUpdate = false;
      this.$refs.formNewDevice.resetValidation();
      this.$refs.formNewDevice.reset();
      this.auxSelects = [];
      this.deviceClass = null;
      this.device = {
        deviceProductId: null,
        device_external_id: null,
        alias: null,
        gps: null,
        measurementUnit: null,
        imp: null,
        transmissionPeriod: null,
        reconfigurationPeriod: null,
        localTransmission: null,
        localUplink: null,
        suministro: null,
        userId: null,
        selects: [],
      };
    },
    update(item) {
      this.deviceClass = {
        telemetry: item.telemetria,
      };
      this.isUpdate = true;
      this.dialog = true;
      this.device = item;
      this.device.gps = item.latitud + ", " + item.longitud;
      this.device.measurementUnit = item.coefficient;
      this.device.selects =
        this.$store.getters.getInputs[item.deviceProductId - 1]?.value;

      item.downlik_config.split("").forEach((element, index) => {
        if (index === 1) return;
        if (index === 4) return;
        if (index === 0) {
          this.auxSelects.push(element + element);
        } else if (index === 3) {
          this.auxSelects.push(element + element);
        } else {
          this.auxSelects.push(element);
        }
      });
      this.device.userIdN1 = item.user.parent_id;
      this.getUserN2();
      this.device.userId = item.user.id;
      this.device.deviceProductId = item.device_product.id;

      this.auxSelects.forEach((element, index) => {
        this.device.selects[index].valueSelected = element;
      });
    },
    save() {
      if (this.$refs.formNewDevice.validate()) {
        if (!this.isUpdate) {
          BackendApi.post("/device/device", this.device)
            .then((response) => {
              if (response.data.success) {
                this.$emit("onNewDevice");
                this.close();
              }
            })
            .catch((error) => {
              this.isLoading = false;

              if (error.response.data.errors) {
                this.errores[error.response.data.errors[0]?.param] =
                  error.response.data.errors[0]?.msg;
              } else {
                this.errores.name = error.response.data?.msg;
              }
            });
        } else {
          BackendApi.put("/device/device/" + this.device.id, this.device)
            .then((response) => {
              if (response.data.success) {
                this.$emit("onNewDevice");
                this.close();
              }
            })
            .catch((error) => {
              this.isLoading = false;

              if (error.response.data.errors) {
                this.errores[error.response.data.errors[0]?.param] =
                  error.response.data.errors[0]?.msg;
              } else {
                this.errores.name = error.response.data?.msg;
              }
            });
        }
      }
    },
    getProductos() {
      BackendApi.get("/device/deviceByUser")
        .then((response) => {
          if (response.data.success) {
            response.data.data.device_classes.forEach((elementi) => {
              elementi.device_products.forEach((elementj) => {
                const data = {
                  ...elementj,
                  measurement_unit: elementi.measurement_unit,
                };
                this.devices.push(data);
              });
              // this.devices = [...this.devices, ...element.device_products];
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getUserN1() {
      BackendApi.get("/user/usersN1")
        .then((response) => {
          if (response.data.success) {
            this.usersN1 = response.data.data;
          }
        })
        .catch(() => {});
    },
    getUserN2() {
      BackendApi.get("/user/usersN2ForReport/" + this.device.userIdN1)
        .then((response) => {
          if (response.data.success) {
            this.usersN2 = response.data.data;
          }
        })
        .catch(() => {});
    },
    // getUserN2() {
    //   BackendApi.get("/user/usersN2")
    //     .then((response) => {
    //       if (response.data.success) {
    //         this.usersN2 = response.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //
    //     });
    // },
    openMapGps() {
      // this.$refs.waitGps.open();
      this.dialogWaitGps = true;
      if (!this.isUpdate) {
        this.$getLocation()
          .then((coordinates) => {
            let Latitude = 0;
            let Longitude = 0;
            Latitude = coordinates.lat;
            Longitude = coordinates.lng;
            this.$refs.viewMap.open({
              Latitude: Latitude,
              Longitude: Longitude,
            });
            this.dialogWaitGps = false;
            // this.$refs.waitGps.close();
          })
          .catch((error) => {});
        // this.$refs.viewMap.open({
        //   Latitude: -12.055117615196874,
        //   Longitude: -77.03516245791016,
        // });
        // this.dialogWaitGps = false;
      } else {
        this.$refs.viewMap.open({
          Latitude: this.device.latitud,
          Longitude: this.device.longitud,
        });
        this.dialogWaitGps = false;
        // this.$refs.waitGps.close();
      }
      // this.$refs.viewMap.open();
    },
    changeCoordinates(coordinates) {
      if (coordinates) {
        this.coordinates.Latitude = coordinates.lat;
        this.coordinates.Longitude = coordinates.lng;
        this.device.gps = coordinates.lat + ", " + coordinates.lng;
      }
    },
    changeMeasurementUnit() {
      this.deviceClass = this.devices.find(
        (product) => product.id == this.device.deviceProductId
      );

      this.measurementUnit = "IMP/" + this.deviceClass.measurement_unit;
      this.device.selects =
        this.$store.getters.getInputs[this.device.deviceProductId - 1].value;
    },
  },
};
</script>

<style></style>
