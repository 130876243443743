<template>
  <v-row justify="center">
    <v-dialog v-model="dialogWaitGps" persistent max-width="500">
      <v-card>
        <v-progress-linear indeterminate color="green" />
        <v-card-title class="text-h5">
          Obtiendo información GPS <v-icon>mdi-cellphone-marker</v-icon>
        </v-card-title>
        <v-card-text
          >Por favor active su GPS de celular, tablet o laptop.</v-card-text
        >
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialogWaitGps: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
