<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title> Calibrar dispositivo </v-card-title>

        <v-card-text class="mt-8">
          <v-form @submit.prevent="save()" ref="formDeviceCalibrar">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="calibrar"
                  outlined
                  label="Calibrar (M3/kWH)"
                  :rules="
                    ([(v) => !!v || 'Calibrar es requerido'],
                    [(v) => v > 0 || 'Calibrar debe ser un numero'])
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" @click="close()" dark> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save()" dark> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BackendApi from "@/services/backend.service";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      calibrar: null,
      item: null,
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.item = item;
    },
    close() {
      this.dialog = false;
      this.$refs.formDeviceCalibrar.resetValidation();
      this.$refs.formDeviceCalibrar.reset();
    },
    save() {
      if (this.$refs.formDeviceCalibrar.validate()) {
        const payload = {
          calibrar: this.calibrar,
        };

        BackendApi.post("/device/calibrar/" + this.item.id, payload)
          .then((response) => {
            if (response.data.success) {
              // this.$emit("onNewDevice");
              this.close();
            }
          })
          .catch((error) => {
            this.isLoading = false;
            
            // if (error.response.data.errors) {
            //   this.errores[error.response.data.errors[0]?.param] =
            //     error.response.data.errors[0]?.msg;
            // } else {
            //   this.errores.name = error.response.data?.msg;
            // }
          });
      }
    },
  },
};
</script>

<style></style>
