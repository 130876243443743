<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-card-title>
        <v-row class="d-flex flex-row">
          <v-switch v-model="changeGps" label="Ajustar ubic." />
          <v-spacer></v-spacer>
          <v-btn text @click="close()">X</v-btn>
        </v-row>
      </v-card-title>

      <gmap-map :center="center" :zoom="15" style="width: 100%; height: 100%">
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
        </gmap-info-window>
        <gmap-marker
          v-for="(m, i) in markers"
          :key="i"
          :position="m.position"
          :clickable="true"
          :draggable="changeGps"
          @click="toggleInfoWindow(m, i)"
          @drag="updateCoordinates"
        ></gmap-marker>
      </gmap-map>
      <v-card-actions>
        <v-text-field
          v-model="latitude"
          label="Latitud"
          @change="changeCordinateLatitude"
          outlined
        />
        <v-text-field
          v-model="longitude"
          label="Longitud"
          @change="changeCordinateLongitude"
          outlined
        />
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submit"> Confirmar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      latitude: 0,
      longitude: 0,
      dialog: false,
      changeGps: false,
      coordinates: null,
      center: {
        lat: 47.376332,
        lng: 8.547511,
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      markers: [
        {
          position: {
            lat: 47.379592,
            lng: 8.549867,
          },
          infoText: "",
        },
      ],
    };
  },
  methods: {
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    updateCoordinates(location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.latitude = location.latLng.lat();
      this.longitude = location.latLng.lng();
    },
    open(item) {
      this.dialog = true;

      this.coordinates = {
        lat: parseFloat(item.Latitude),
        lng: parseFloat(item.Longitude),
      };

      this.latitude = parseFloat(item.Latitude);
      this.longitude = parseFloat(item.Longitude);
      this.center = this.coordinates;

      this.markers[0].position = this.coordinates;
    },
    close() {
      this.dialog = false;
    },
    submit() {
      this.$emit("changeCoordinates", this.coordinates);
      this.close();
    },
    changeCordinateLatitude() {
      this.coordinates = {
        ...this.coordinates,
        lat: parseFloat(this.latitude),
      };
      this.center = this.coordinates;
      this.markers[0].position = this.coordinates;
    },
    changeCordinateLongitude() {
      this.coordinates = {
        ...this.coordinates,
        lng: parseFloat(this.longitude),
      };
      this.center = this.coordinates;
      this.markers[0].position = this.coordinates;
    },
  },
};
</script>
