<template>
  <div>
    <v-row class="pa-8 px-8">
      <v-spacer></v-spacer>
      <v-btn class="mr-2" color="primary" dark @click="openNewDevice"
        ><v-icon>fa-plus</v-icon></v-btn
      >
    </v-row>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar dispositivo"
          single-line
          hide-details
          outlined
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="devices"
          :headers="headers"
          :loading="isLoadingTable"
          :search="search"
        >
          <template v-slot:[`item.telemetria`]="{ item }">
            <v-icon v-if="item.telemetria">fa-check</v-icon>
            <div v-else>
              <svg
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
                />
              </svg>
            </div>
          </template>
          <template v-slot:[`item.telecontrol`]="{ item }">
            <v-switch
              v-if="item.telecontrol"
              v-model="item.deviceActive"
              @change="changeActiveDevice(item)"
            ></v-switch>
            <!-- <v-icon v-if="item.telecontrol">fa-check</v-icon> -->
            <div v-else>
              <svg
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
                />
              </svg>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" v-on="on"> Acciones </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="openUpdateDevice(item)">
                  Modificar
                </v-list-item>
                <template>
                  <div class="text-center">
                    <v-dialog
                      v-model="dialogConfirm"
                      persistent
                      max-width="400"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item link v-bind="attrs" v-on="on">
                          Eliminar
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Seguro de eliminar este usuario?
                        </v-card-title>
                        <v-card-text
                          >Una vez eliminado este usuario, no podrá recuperarlo.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="dialogConfirm = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="primary"
                            dark
                            text
                            @click="deleteDevice(item)"
                          >
                            Confirmo
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
                <v-list-item link @click="calibrar(item)">
                  Calibrar
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- <v-btn @click="openMapGps">click</v-btn> -->
    <NewDevice ref="newDevice" @onNewDevice="onNewDevice" />
    <CalibrarDeviceComponent
      ref="deviceCalibrar"
      @onDeviceCalibrar="onDeviceCalibrar"
    />
  </div>
</template>

<script>
import NewDevice from "./components/NewDeviceComponent.vue";
import CalibrarDeviceComponent from "./components/CalibrarDeviceComponent.vue";

import BackendApi from "@/services/backend.service";

export default {
  components: {
    NewDevice,
    CalibrarDeviceComponent,
  },
  data() {
    return {
      search: "",
      deviceActive: false,
      dialogWaitGps: false,
      dialogConfirm: false,
      isLoadingTable: false,
      products: ["sadihasd", "salknda", "as98dsdasd", "sa8d8as9d"],
      // color: { hex: null },
      // isBrandBlank: false,
      dialog: false,
      headers: [
        { text: "Id", value: "device_external_id" },
        { text: "Alias", value: "alias" },
        { text: "Producto", value: "product" },
        { text: "Id usuario N2", value: "id_user_n2" },
        { text: "Usuario N2", value: "user_n2" },
        { text: "Telemetría", value: "telemetria" },
        { text: "Telecontrol", value: "telecontrol" },
        { text: "Acciones", value: "actions" },
      ],
      devices: [],
    };
  },
  created() {
    this.getDevices();
  },
  methods: {
    openNewDevice() {
      this.$refs.newDevice.open();
    },
    async getDevices() {
      
      this.devices = [];
      this.isLoadingTable = true;
      const { data } = await BackendApi.get("/device/device");
      if (data.success) {
        data.data.forEach((device) => {
          device.downlik_config.split("").forEach((element, index) => {
            if (index === 3) {
              if (element + element === "FF") {
                this.deviceActive = true;
              } else {
                this.deviceActive = false;
              }
            }
          });
          const payload = {
            ...device,
            id: device.id,
            device_external_id: device.device_external_id,
            alias: device.alias,
            product: device.device_product.name,
            id_user_n2: device.user.id_usuario,
            user_n2: device.user.email,
            telemetria: device.device_product.telemetry,
            telecontrol: device.device_product.telecontrol,
            deviceActive: this.deviceActive,
            actions: "actions",
          };
          this.devices.push(payload);
        });
        
        this.isLoadingTable = false;
      } else {
        this.isLoadingTable = true;
      }
    },
    openUpdateDevice(item) {
      this.$refs.newDevice.update({ ...item });
    },
    deleteDevice(item) {
      BackendApi.delete("/device/device/" + item.id)
        .then((response) => {
          if (response.data.success) {
            this.dialogConfirm = false;
            this.getDevices();
          }
        })
        .catch((error) => {
          
        });
    },
    onNewDevice() {
      this.getDevices();
    },
    changeActiveDevice(item) {
      
      const payload = {
        deviceActive: item.deviceActive,
      };
      BackendApi.post("/device/changeActiveDevice/" + item.id, payload)
        .then((response) => {
          if (response.data.success) {
            // this.getDevices();
          }
        })
        .catch((error) => {
          
        });
    },
    calibrar(item) {
      
      this.$refs.deviceCalibrar.open(item);
    },
    onDeviceCalibrar() {
      
    },
  },
};
</script>
